@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/light';
@import 'node_modules/ekko-lightbox/ekko-lightbox.less';
@import 'node_modules/slick-carousel/slick/slick.scss';

@mixin transition-all() {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  @include transition-all();

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $color;
    background-color: darken($background, 5%);
    border-color: darken($border, 7%);
  }

  &:active,
  &.active,
  .open > .dropdown-toggle & {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

@mixin sans-serif-font() {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

@mixin serif-font() {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}

$grey-base:        #000;
$grey-darker:      lighten($grey-base, 13.5%); // #222
$grey-dark:        lighten($grey-base, 20%);   // #333
$grey:             lighten($grey-base, 33.5%); // #555
$grey-light:       lighten($grey-base, 46.7%); // #777
$grey-lighter:     lighten($grey-base, 93.5%); // #eee
$grey-lightest:    lighten($grey-base, 100%); // #fff

$base1: rgba(0, 28, 40, 1); // #001c28
$base2: rgba(51, 96, 116, 1); // #336074
$base3: rgba(0, 57, 82, 1); // #003952

$accent1: rgba(0, 138, 172, 1); // #008aac
$accent2: rgba(255, 255, 255, 1); // #ffffff

$enable-rounded: false;
$mobile-breakpoint: 992px;

$font-heading: 'Fjalla One', sans-serif;
$font-body: 'Open Sans', sans-serif;
$fa-font-path:   '../fonts';

.bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-dark {
  background-color: rgba($base1, .6);
}

.bg-overlay-light {
  background-color: rgba($grey-lightest, .6);
}

.btn {
  margin-bottom: 1em;
  padding: .75rem 1.75rem;
  line-height: 1em;
  font-size: 22px;
}

.btn-sm {
  font-size: 18px;
}

.btn-outline {
  border: 3px solid $accent2;
  color: $accent2;
}

.btn-primary {
  background-color: $base1;
}

.btn-secondary {
  background-color: $base2;
}

.btn-grey {
  background-color: $grey;
}

.btn-transparent {
  background-color: transparent;

  &:hover {
    background-color: $accent1;
    color: $accent2;
  }
}

.btn-wrapper {
  width: 100%;

  @media (min-width: 576px) {
    width: auto;
  }
}

// BUTTON COMPONENT
.col {
  &:not(.justify-content-center) {
    .btn {
      width: 100%;

      @media (min-width: 576px) {
        width: auto;
      }
    }
  }
}

.page-footer {
  background-color: $base1;
  text-transform: uppercase;
  color: $accent2;

  .flex-col {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}

.footer-item {
  margin: 0 1.5rem;
  color: $accent2;
  font-family: $font-heading;
  font-size: 18px;

  @media (max-width: 576px) {
    margin: .5rem 2rem;
  }
}

.footer-upper {
  background-color: $base3;
  padding: 2rem 0;

  .footer-item {
    &:hover {
      color: $accent1;
    }
  }
}

.footer-lower {
  padding: 1.5rem 0;

  .footer-item {
    margin-bottom: .5rem;
  }

  .footer-credit {
    margin: 0 12px;
    font-size: 12px;
    font-weight: bold;
  }
}

.bullet-separator {
  margin-bottom: .5rem;
  line-height: 1em;
  font-size: 2em;
}

.form-group {
  margin-bottom: 30px;
}

.form-control {
  border: 4px solid $accent2;
  background-color: rgba($accent2, .8);
  padding: 1.25em;
  color: $base1;

  &::placeholder {
    text-transform: uppercase;
    color: $base3;
  }
}

.entry-tile,
.masonry-item,
.gallery-image {
  margin-bottom: 15px;
  @media(min-width: 576px) {
    margin-bottom: 30px;
  }
}

.entry-tile {
  .tile-contents {
    text-decoration: none;

    &:hover {
      color: $accent2;

      .title-overlay {
        opacity: 1;
      }
    }
  }

  .image-wrapper {
    position: relative;

    .title-overlay {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      transition: opacity .3s ease-in-out;
      opacity: 0;
      background-color: rgba($base3, .8);

      .btn {
        margin-bottom: 1em;
        min-width: 160px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .title-footer {
    .entry-tile-heading {
      margin-bottom: 0;
      background-color: $base2;
      padding: .5em;
      text-align: center;
    }

    .entry-tile-address {
      background-color: $base3;
      padding: .5em;
      text-align: center;
      font-size: 12px;
    }
  }
}

// EKKO LIGHTBOX
.modal-body {
  padding: 0;
}

.ekko-lightbox-nav-overlay {

  a {
    transition: color .3s ease-in-out;
    opacity: 1;
    color: $accent2;

    &:hover {
      color: $accent1;
    }
  }
}





// NEW CAROUSEL
// .slick-active .carousel-item {
//   display: block;
// }

.slick-slide img {
  width: 100%;
}
// .carousel-item-link {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: 150px;
  height: 100%;
  line-height: 0;
  color: transparent;
  font-size: 0;

  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }

  &:hover {
    &::before {
      opacity: 1;
      border-color: $accent1;
    }
  }

  &.slick-disabled {
    &::before {
      opacity: .25;
    }
  }

  @media(max-width: $mobile-breakpoint) {
    width: 50px;
  }
}

.slick-prev {
  left: 0;

  &::before {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border: 8px solid $grey-lightest;
    border-top: 0;
    border-right: 0;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    content: '';

    &:hover {
      border-color: $accent1;
    }

    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
}






.slick-next {
  right: 0;

  &:before {
    position: absolute;
    right: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border: 8px solid $grey-lightest;
    border-bottom: 0;
    border-left: 0;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    content: '';

    &:hover {
      border-color: $accent1;
    }

    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
}





.carousel-item-link {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity .5s ease-in-out;
    opacity: .5;
    background-color: $base2;
    content: '';
  }
}

.slick-center {
  .carousel-item-link {
    &::before {
      opacity: 0;
    }
  }
}

.property-tile-overlay {
  display: none;
}

.slick-center {
  .property-tile-overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .property-name,
    .btn-group {
      display: none;
    }

    .btn {
      margin: 0 0.5em;
      min-width: 150px;
    }

    &:hover {
      background-color: rgba($base3, .75);
      color: $accent2;

      .property-name,
      .btn-group {
        display: block;
      }
    }
  }
}






.carousel-nav-pills {
  display: none;
  position: absolute;
  width: 100%;
  bottom: -25px;
  list-style: none;
  justify-content: center;
  margin: 16px 0 0;
  padding: 0;

  li {
    display: block;
    width: 32px;
    height: 3px;
    font-size: 0;
    line-height: 0;
    border: 0;
    margin: 0 3px;
    background-color: $grey-lightest;
    opacity: 0.35;

    &:hover {
      opacity: 0.5;
    }

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 28px;
      width: 32px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
    }

    &.slick-active {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.nav-logo {
  width: 82px;

  @media (min-width: $mobile-breakpoint) {
    width: 138px;
  }
}

.navbar {
  transition: background-color .3s ease-in-out;
  background-color: $base3;
  text-transform: uppercase;
  font-family: $font-heading;
  font-size: 36px;

  @media (min-width: $mobile-breakpoint) {
    background-color: transparent;
    padding: 1.5rem 2rem;
    font-size: 18px;
  }

  .navbar-nav {
    margin-top: 1rem;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 0;
    }

    .nav-link {
      padding: .5rem 1rem;
      color: $accent2;

      @media(min-width: $mobile-breakpoint) {
        padding: 0 1em;
        text-align: center;
      }

      &:hover {
        color: $accent1;
      }
    }
  }

  .nav-item {
    &.active {
      .nav-link {
        color: $accent1;
      }
    }
  }

  &.scrolled {
    background-color: $base3;
  }
}

.social-links {
  margin-bottom: 1rem;

  @media (min-width: $mobile-breakpoint) {
    margin-bottom: 0;
  }
}

.connect-menu-button {
  padding: 1rem;

  @media(min-width: $mobile-breakpoint) {
    padding: 0 2em;
  }
}

#current-projects-carousel {
  padding-top: 35px;
}

.page-section {
  position: relative;
  padding: 100px 0;

  &.full-height {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  &:first-of-type {
    padding-top: 150px;
  }
}

.hide {
  display: none;
}

.bleedToEdge {
  > .row {
    margin-right: 0;
    margin-left: 0;

    > .col-12 {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.collapsible-panel-control {
  position: absolute;
  right: 12px;
  bottom: 12px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.collapsible-panel-label {
  transition: opacity .3s ease-in-out;
}

.page-section {
  &.open-panel {
    transition: all .3s ease-in-out;

    .container {
      opacity: 1;

      &.collapsible-panel-wrapper {
        .collapsible-panel-label {
          opacity: 0;
        }
      }

      &:not(.collapsible-panel-wrapper) {
        opacity: 1;
        transition: opacity .3s ease-in-out;
      }
    }

    .collapsible-panel-control {
      transform: rotate(45deg);

      &:hover {
        animation: wiggleClose .5s infinite;
      }
    }
  }

  &.close-panel {
    transition: all .3s ease-in-out;
    padding: 0;
    min-height: 55px;

    .container {
      display: none;
      opacity: 1;

      &.collapsible-panel-wrapper {
        display: block;

        .collapsible-panel-label {
          opacity: 1;
          padding: 14px 20px 14px 10px;
        }
      }

      &:not(.collapsible-panel-wrapper) {
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
    }

    .collapsible-panel-control {
      transform: rotate(0deg);

      &:hover {
        animation: wiggleOpen .5s infinite;
      }
    }
  }
}

.page-section {
  &.full-height {
    &.open-panel {
      height: calc(100vh - 50px);
    }
    &.close-panel {
      height: auto;
    }
  }
}
@keyframes wiggleOpen {
  0% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(4deg);
  }

  100% {
    transform: rotate(-4deg);
  }
}

@keyframes wiggleClose {
  0% {
    transform: rotate(41deg);
  }

  50% {
    transform: rotate(49deg);
  }

  100% {
    transform: rotate(41deg);
  }
}

.arrow-down {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 8px solid $grey-lightest;
  border-top: 0;
  border-left: 0;
  border-radius: 5px;
  width: 32px;
  height: 32px;

  &:hover {
    border-color: $accent1;
  }

  @media (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.mouse {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid $grey-lightest;
  border-radius: 26px;
  width: 26px;
  height: 42px;
  -webkit-backface-visibility: hidden;

  &::after {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateY(0) scaleY(1) scaleX(1) translateZ(0);
    animation: scrollwheel 1.5s -1s cubic-bezier(.68, -.55, .265, 1.55) infinite;
    opacity: 1;
    margin-left: -2px;
    border-radius: 100%;
    background-color: $grey-lightest;
    width: 4px;
    height: 4px;
    content: '';
  }

  @media (max-width: $mobile-breakpoint) {
    display: none;
  }
}

@keyframes scrollwheel {
  0%,
  20% {
    transform: translateY(0) scaleY(1) scaleX(1) translateZ(0);
  }

  10% {
    transform: translateY(0) scaleY(1.2) scaleX(1.2) translateZ(0);
    opacity: 1;
  }

  100% {
    transform: translateY(20px) scaleY(2.5) scaleX(.5) translateZ(0);
    opacity: .01;
  }
}

body {
  font-family: $font-body;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn {
  text-transform: uppercase;
  font-family: $font-heading;
}

h1 {
  margin-bottom: 1rem;
  font-size: 36px;

  @media (min-width: $mobile-breakpoint) {
    margin-bottom: 2rem;
    font-size: 66px;
  }
}

h2 {
  margin-bottom: 1rem;
  font-size: 32px;

  @media (min-width: $mobile-breakpoint) {
    margin-bottom: 2rem;
    font-size: 50px;
  }
}

p {
  margin-bottom: 2rem;
}

.bg-dark {
  color: $accent2;
}

a {
  color: $accent2;

  &:hover {
    color: $accent1;
  }
}

.text-size-standard {
  font-size: 1em;
}

.text-size-larger {
  font-size: 1.5em;
}

.text-size-huge {
  font-size: 3em;
}

.text-block {
  p {
    margin-bottom: 1em;
  }
}
